// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

::-webkit-scrollbar {
  width: 5px;
}
  ::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
  ::-webkit-scrollbar-thumb {
  background: #888; 
    border-radius:10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
} 

